import React, { useState, useEffect } from 'react';
import './index.css';
import influencersData from './influencers.json';

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeRegion, setActiveRegion] = useState('TH');
  const [filteredInfluencers, setFilteredInfluencers] = useState(influencersData);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    closeMenu();
  };

  useEffect(() => {
    filterInfluencers(activeRegion);
  }, [activeRegion]);

  const filterInfluencers = (region) => {
    if (region === 'all') {
      setFilteredInfluencers(influencersData);
    } else {
      const filtered = influencersData.filter(influencer => influencer.region.toLowerCase() === region.toLowerCase());
      setFilteredInfluencers(filtered);
    }
  };

  const handleRegionChange = (region) => {
    setActiveRegion(region);
  };

  const formatFollowers = (followers) => {
    if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + 'M';
    } else if (followers >= 1000) {
      return (followers / 1000).toFixed(1) + 'K';
    } else {
      return followers.toString();
    }
  };

  return (
    <div className="page-wrapper smooth-scroll">
      <header className="site-header">
        <nav className="container main-nav">
          <a href="/" className="logo">
            <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/startlogo.png" alt="StarTop Media Logo" className="logo-image" />
            <span className="logo-text">StarTop Media</span>
          </a>
          <button className="mobile-menu-btn" onClick={toggleMenu} aria-label="打开菜单">☰</button>
          <ul className={`nav-list ${isMenuOpen ? 'show' : ''}`}>
            <li className="nav-item"><a href="#about" onClick={() => scrollToSection('about')} className="nav-link">关于我们</a></li>
            <li className="nav-item"><a href="#services" onClick={() => scrollToSection('services')} className="nav-link">业务范围</a></li>
            <li className="nav-item"><a href="#cases" onClick={() => scrollToSection('cases')} className="nav-link">合作案例</a></li>
            <li className="nav-item"><a href="#contact" onClick={() => scrollToSection('contact')} className="nav-link">联系我们</a></li>
          </ul>
        </nav>
      </header>

      <main className="main-content">
        <section id="about">
          <div className="content-area">
            <p className="section-content"><strong>StarTop Media</strong>团队成员来自<strong>中国、泰国、越南和印尼</strong>，曾供职于多家一线互联网企业，均拥有<strong>5年以上</strong>的互联网海外用户增长经验。</p>
            <p className="section-content">我们专长于海外市场的<strong>网红营销</strong>，以及三大平台（TikTok、Google Ads、Facebook）的<strong>广告投放</strong>。</p>
            <p className="section-content">团队深耕泰国、越南、马来西亚、印尼、美国、西班牙和日本市场。多年的网红营销工作使我们积累了丰富的网红和MCN资源，熟悉当地网红的<strong>市场价格</strong>和<strong>合作方式</strong>，能够提供<strong>大规模</strong>的网红营销一站式服务。</p>
            <p className="section-content">同时，我们还可以利用网红资源<strong>批量</strong>制作投放素材，并提供专业的<strong>广告投放</strong>服务。</p>
          </div>
        </section>

        <section id="services" className="section container">
          <h2 className="section-title">业务范围</h2>
          <div className="content-area">
            <ul className="service-list">
              <li className="service-item"><strong>网红营销</strong>：StarTop团队根据客户需求提供网红名单和报价。客户确认最终合作名单后，需在网红开始制作视频前支付50%定金。收到定金后，我们将跟进网红进行广告视频制作、提交客户审核、协调修改并发布视频。客户需在视频发布后7天内结清剩余50%尾款。</li>
              <li className="service-item"><strong>广告投放</strong>：按广告消耗的8%收取服务费。</li>
              <li className="service-item"><strong>KOC/KOL广告素材制作</strong>：根据客户需求，我们提供KOC/KOL名单和单个视频素材报价。StarTop团队将按照客户提供的脚本，指导KOC/KOL进行视频素材拍摄</li>
            </ul>
          </div>
        </section>

        <section id="advantages" className="section container">
          <h2 className="section-title">团队优势</h2>
          <div className="content-area">
            <div className="advantage-grid">
              <div className="advantage-item">
                <h3 className="advantage-title">低成本高效益</h3>
                <p className="advantage-description">凭借与多国网红和MCN的长期合作关系，我们能以极具竞争力的价格提供高质量的营销服务，帮助您实现低成本高效益的用户增长。</p>
              </div>
              <div className="advantage-item">
                <h3 className="advantage-title">多元化市场覆盖</h3>
                <p className="advantage-description">我们的团队深耕东南亚、北美和日本等多个市场，对各地文化、消费者行为和营销趋势有深刻理解，能为您的产品制定最适合的本地化推广策略。</p>
              </div>
              <div className="advantage-item">
                <h3 className="advantage-title">丰富的成功案例</h3>
                <p className="advantage-description">我们已成功为网易、百度等知名企业推广多款应用、游戏和电商产品，积累了丰富的经验和令人瞩目的成绩，能够为您的项目带来显著成效。</p>
              </div>
              <div className="advantage-item">
                <h3 className="advantage-title">一站式营销解决方案</h3>
                <p className="advantage-description">从网红筛选到创意策划，从内容制作到效果监测，再到数据分析，我们提供全方位的专业服务，确保您的营销活动从始至终都能得到最优化的执行。</p>
              </div>
            </div>
          </div>
        </section>

        <section id="cases" className="section container">
          <h2 className="section-title">合作案例</h2>
          <div className="case-studies">
            <div className="case-study">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/eggparty.png" alt="eggparty" className="case-study-image" />
              <h3 className="case-study-title">Egg Party 蛋仔派对 MY</h3>
              <p className="case-study-description">单季度活动合作100+网红，发布视频300+，累计曝光量超过5000万次，有效提升了游戏知名度和促进了游戏下载量的增长。</p>
            </div>
            <div className="case-study">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/edifier.png" alt="edifier" className="case-study-image" />
              <h3 className="case-study-title">漫步者耳机 TikTok Shop TH&VN</h3>
              <p className="case-study-description">通过批量网红营销，助力漫步者耳机在双9和双11期销量突破10万件，在泰国和越南市场有效的提高了漫步者耳机的市场知名度。</p>
            </div>
            <div className="case-study">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/starmaker.png" alt="starmaker" className="case-study-image" />
              <h3 className="case-study-title">StarMaker TH</h3>
              <p className="case-study-description">通过网红营销和广告投放，用时3个月以低于平时买量价格3倍的获客成登顶，最佳榜单成绩为在泰国实现连续霸榜总榜（免费）TOP1近一周，8个月总获客100w+。</p>
            </div>
            <div className="case-study">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/noxcleaner.webp" alt="noxcleaner" className="case-study-image" />
              <h3 className="case-study-title">Nox Cleaner TH</h3>
              <p className="case-study-description">通过网红营销和广告投放，泰国全年累积拉新100w+，多次冲榜成功，最佳冲榜成绩为总榜（免费）、应用榜（免费）、工具榜（免费）三榜同时第一。</p>
            </div>
          </div>
        </section>

        <section id="influencers" className="section container">
          <h2 className="section-title">网红列表</h2>
          <p className="influencer-disclaimer">*以下是我们合作网红中的部分tiktok精选代表,仅供参考。我们的合作网红资源远不止于此,可根据您的具体需求提供更多选择。</p>
          
          <div className="region-tabs">
            {/* <button 
              className={`region-tab ${activeRegion === 'all' ? 'active' : ''}`} 
              onClick={() => handleRegionChange('all')}
            >
              全部
            </button> */}
            <button 
              className={`region-tab ${activeRegion === 'TH' ? 'active' : ''}`} 
              onClick={() => handleRegionChange('TH')}
            >
              泰国
            </button>
            <button 
              className={`region-tab ${activeRegion === 'VN' ? 'active' : ''}`} 
              onClick={() => handleRegionChange('VN')}
            >
              越南
            </button>
            <button 
              className={`region-tab ${activeRegion === 'US' ? 'active' : ''}`} 
              onClick={() => handleRegionChange('US')}
            >
              美国
            </button>
            <button 
              className={`region-tab ${activeRegion === 'JP' ? 'active' : ''}`} 
              onClick={() => handleRegionChange('JP')}
            >
              日本
            </button>
            {/* 可以根据需要添加更多地区的按钮 */}
          </div>

          <div className="influencer-grid">
            {filteredInfluencers.map((influencer, index) => (
              <a
                key={index}
                href={`https://www.tiktok.com/@${influencer.tiktok_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="influencer-card"
                data-platform="tiktok"
                data-region={influencer.region.toLowerCase()}
              >
                <img src={influencer.avatar} alt={influencer.nickname} className="influencer-avatar" />
                <h3 className="influencer-name">{influencer.nickname}</h3>
                <p className="influencer-followers">粉丝数: {formatFollowers(influencer.followers)}</p>
              </a>
            ))}
          </div>
        </section>

        <section id="partners" className="section container">
          <h2 className="section-title">合作伙伴</h2>
          <div className="brand-list">
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/netease.png" alt="NetEase Logo" className="brand-logo" />
              <div className="brand-name">网易</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/baidu.png" alt="Baidu Logo" className="brand-logo" />
              <div className="brand-name">百度</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/tiktok.png" alt="TikTok Logo" className="brand-logo" />
              <div className="brand-name">TikTok</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/taobao.png" alt="Taobao Logo" className="brand-logo" />
              <div className="brand-name">淘宝</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/mihoyo.png" alt="miHoYo Logo" className="brand-logo" />
              <div className="brand-name">米哈游</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/eggparty.png" alt="Egg Party Logo" className="brand-logo" />
              <div className="brand-name">蛋仔派对</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/kunlun.png" alt="Kunlun Logo" className="brand-logo" />
              <div className="brand-name">昆仑万维</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/qunar.png" alt="Qunar Logo" className="brand-logo" />
              <div className="brand-name">去哪儿旅行</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/nox.png" alt="Nox Logo" className="brand-logo" />
              <div className="brand-name">夜神NOX</div>
            </div>
            <div className="brand-item">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/edifier.png" alt="Edifier Logo" className="brand-logo" />
              <div className="brand-name">漫步者耳机</div>
            </div>
          </div>
        </section>

        <section id="contact" className="section container">
          <h2 className="section-title">联系我们</h2>
          <div className="contact-content">
          <div className="contact-map">
              <img src="https://my-netease-game-1258537270.cos.ap-singapore.myqcloud.com/sites/imgs/map.png" alt="地图" />
            </div>
            <div className="contact-info">
              <p style={{margin: 0}}>公司地址：<span>广州市番禺区番禺广场</span></p>
              <p style={{margin: 0}}>商务邮箱：<a href="mailto:adela@xingzhituo.media" className="contact-link">adela@xingzhituo.media</a></p>
            </div>
          </div>
        </section>
      </main>

      <footer className="site-footer">
        <div className="container">
          <p className="copyright">&copy; 2024 StarTop Media. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;